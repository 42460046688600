<template>
  <div>
    <CCard style="height: 155px">
      <Summation ref="summationView" />
    </CCard>

    <CCard>
      <Device ref="deviceView" @change="onChangedSite" prefix="door_"/>
    </CCard>

    <Event ref="eventView" @update="updateSummation" @click="onClickEvent" prefix="door_"/>
  </div>
</template>

<script>
import Dashboard from '@/views/dashboard/Dashboard'
import Summation from "@/views/door/Summation";
import Device from "@/views/door/Device";

export default {
  name: 'DoorBoard',
  extends: Dashboard,
  components: {
    Summation,
    Device
  }
};
</script>
