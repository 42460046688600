<script>
import Device from '@/views/dashboard/Device'
import loopback from '@/services/loopback';
import utils from '@/services/utils';
import CronParser from 'cron-parser'

import {
  DEVICE_TABLE_OPTIOINS, DOOR_DEVICE_TABLE_COLUMS
} from "@/constants/deviceSettings";

export default {
  name: 'DoorDevice',
  extends: Device,
  props: {
    prefix: {
      type: String,
      default: 'door_'
    }
  },
  methods: {
    makeTable(site_guids) {
      if (site_guids.length === this.siteTables.length) {
        return;
      }
      this.siteTables = [];

      DEVICE_TABLE_OPTIOINS.sortName="name";
      DEVICE_TABLE_OPTIOINS.sortOrder="asc";
      //console.log(DEVICE_TABLE_OPTIOINS);

      for (var i = 0; i < site_guids.length; i++) {
        var table = {
          options: DEVICE_TABLE_OPTIOINS,
          columns: DOOR_DEVICE_TABLE_COLUMS,
          title: '',
          data: []
        }
        _.find(table.columns, { field: 'name' }).formatter = this.nameFormatter;
        _.find(table.columns, { field: 'sensor' }).formatter = this.sensorFormatter;
        _.find(table.columns, { field: 'level' }).formatter = this.imageFormatter2;
        _.find(table.columns, { field: 'battery' }).formatter = this.batteryStatusFormatter;
        _.find(table.columns, { field: 'sensor_time' }).formatter = utils.dateFormatter;

        this.siteTables.push(table)
      }
    },
    imageFormatter(value, row, sensor_data) {
      if (_.isUndefined(value) || value.length < 1)
        return this.buildsensorFormatter(sensor_data, row);

      var label = '';
      var label_text = "충격감지";
      sensor_data.data.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          if (v.id === 0) v.id = 1;
          switch(v.type){
            case 9:
              label = v.value == 1 ? "<span style = 'color: red; font-weight: bold; background: rgba(0,0,0,0.7);'>"+ label_text + " </span>"  : '';
              break;
            case 18:
              label_text = "경 고";
              label = v.value == 1 ? "<span style = 'color: red; font-weight: bold; background: rgba(0,0,0,0.7);'>"+ label_text + " </span>"  : '';
              break;
          }
        }
      })
      var images = []
      var device_url = this.getDeviceUrl(row);
      for (var i = 0; i < value.length; i++) {
        var el = value[i]
        if (_.isUndefined(el.storage)) {
          continue
        }
        var url = loopback.defaults.baseURL + '/' + el.storage + 's/' + el.device_guid + '/download/' + el.file_name;
        images.push({
          url: url,
          label: label,
          created_at: el.created_at
        })
      }
      images = _.sortBy(images, ['created_at']).reverse();
      var target_id = 'img_' + row.guid
      var result = '<div id="' + target_id + '" class="carousel slide" data-ride="carousel" data-interval="5000">' +
        '<ol class="carousel-indicators hidden">';      

      result += '</ol>';
      var img = '<a href="{0}"><img src="{1}" alt="" style="width: 150px; height: 80px;"></a>'
      var caption = '<div class="carousel-caption"><p>{0}</p></div></div>'
      result += img.format(device_url, images[0].url)
      result += caption.format(images[0].label)

      result += '<style scoped>'
      result += '.carousel-indicators {'
      result += '  bottom: -25px;'
      result += '  z-index: 10;'
      result += '}'
      result += '.carousel-inner {'
      result += '  width: 100%;'
      result += '  max-height: 256px !important;'
      result += '  background-color: silver !important;'
      result += '}'
      result += '.carousel-caption {'
      result += '  bottom: -10px;'
      result += '  z-index: 10;'
      result += '  padding-top: 20px;'
      result += '  padding-bottom: 0px;'
      result += '}'
      result += '</style>'
      return result;
    },
    imageFormatter2(value, row, index) {
      var result="닫힘";
      var doorBtn = "success";

       if(value === 1){
          doorBtn = 'danger';            
          result = "열림"
      }
      return '<button style="margin:0;cursor:default" class="btn btn-' + doorBtn + ' btn-sm">' + result+ '</button>';
    },    
    levelFormatter(row) {
      var level = 0;
      row.sensor.data.forEach(function(e){
        if(e.type === 18){
          if(e.value === 1)
            level = 1;
        }
      })
    
      return level;
    },        
    batteryStatusFormatter(value, row, index) {
      var result = '';
      var batteryBtn = '';
      var thresholds = utils.getThresholds();
      var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
      var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
      var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
      var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
      if (_.isUndefined(row.sensor)){
          console.log("isUndefined");
          return;
      }

      row.sensor.data.forEach(function(e){
        if(e.type === 1)
          result = e.value;
      })

      if (result >= level0){ 
        batteryBtn = 'success';
      }
      else if (result >= level1 ){ 
        batteryBtn = 'warning'
      }
      else if (result >= level2 ){
        batteryBtn = 'warning'          
      }
      else if (result >= level3 ){
        batteryBtn = 'danger'
      }
      else{
        batteryBtn = 'danger' 

      }
      return '<button style="margin:0;cursor:default" class="btn btn-' + batteryBtn + ' btn-sm">' + result+ ' (V) </button>';
    },    
    sensorFormatter(value, row, index) {
      var self = this;
      if (row.service_type === 'door') {
        return self.imageFormatter(row.images, row, value);
      }
      return self.buildsensorFormatter(value, row);
    },
    buildsensorFormatter(value, row){
      var self = this
      var result = "";
      value.data = _.sortBy(value.data, ['type']);
      value.data.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          if (v.id === 0) v.id = 1;
          var description = _.find(self.sensor_type, {
            code: v.type,
            id: v.id
          });
          if (!description) return
          
          var desc = "";
          switch(v.type){
            case 1:
              //desc = '배터리:' + v.value + description.unit + ' '; 
              break;
            case 8:
              if(v.id === 1) desc = 'x축:' + v.value + description.unit + ' ';
              else if(v.id === 2) desc = 'y축:' + v.value + description.unit + ' ';
              else if(v.id === 3) desc = 'z축:' + v.value + description.unit + ' ';
              break;
            case 9:
              desc = v.value == 1 ? "<br><span style = 'color: red; font-weight: bold;'> 충격감지 </span>"  : '';
              break;
            case 18:
              desc = v.value == 1 ? "<br><span style = 'color: red; font-weight: bold;'> 문 열림 </span>"  : '';
              break;              
          }
          result += desc;
        }
        else
          result = "측정값 없음";
      })
      return "<a>" + result + "</a>";
    },
    getDeviceList(index, limit, skip) {
      var self = this;
      if (typeof limit === 'undefined') limit = 50
      if (typeof skip === 'undefined') skip = 0

      var default_site_guid = _.get(this.user, "default_site_guid");
      var active_site_index = _.get(this.user, "active_site_index", 0)
      var data = {
        limit: limit,
        skip: skip,
        site_guid: _.isEmpty(this.user.active_site_guids) ? this.user.site_guids : this.user.active_site_guids,
        active_site_index: active_site_index
      }

      // this.makeTable(data.site_guid)

      return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
          var has_watering = false;
          var has_nutrient = false;
          // console.log('Device::getDeviceList: ', JSON.stringify(res,null,2))
          self.makeTable(_.map(res, 'site_guid'))
          self.user.active_site_guids = []
          res.forEach(function(d, i) {
            var rows = [];
            d.data.forEach(function(e) {
              var interval = CronParser.parseExpression(e.upload_cron)
              var data = {
                site: d.Site.name,
                site_guid: d.Site.guid,
                site_index: i,
                center_device_guid: d.Site.center_device_guid,
                latitude: e.latitude,
                longitude: e.longitude,
                name: e.name,
                service_type: e.service_type,
                address: e.address,
                sensor: e.sensor,
                standard: e.standard,
                empty: e.sensor.empty,
                sensor_time: e.sensor.created_at,
                guid: e.guid,
                threshold: e.threshold,
                images: e.images,
                level: self.levelFormatter(e)
              }
              rows.push(data)
            });
            var active_site_index = _.get(self.user, "active_site_index", -1);
            if (active_site_index != -1 && self.active_site_index != active_site_index) {
              if (active_site_index < res.length)
                self.active_site_index = active_site_index;
            } else {
              if (default_site_guid == d.site_guid) {
                self.active_site_index = i;
              }
            }

            if (_.indexOf(self.user.active_site_guids, d.site_guid) === -1) {
              self.user.active_site_guids.push(d.site_guid);
            }
            self.siteTables[i].title = d.Site.name;
            self.siteTables[i].data = rows;
            self.siteTables[i].device_count = rows.length;
          });
          var siteTable = self.siteTables[self.active_site_index]
          self.user.active_site_name = siteTable.title;
          utils.setStorage("userInfo", self.userInfo);
          if (!_.isUndefined(self.$refs.googleMap))
            self.$refs.googleMap.update(siteTable);

          // notify to parent : change
          self.$emit('change', siteTable)

          return Promise.resolve(siteTable)
        })
    } // getDeviceList
  }
}
</script>

